.nav-ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    font-family: 'Alpino', sans-serif;
    font-weight: 700;
    font-size: 5rem;

    & button {
        font-family: 'Alpino', sans-serif;
        font-weight: 700;
        font-size: 5rem;
        align-items: center;
        text-transform: uppercase;
    }
}

.nav-li {
    opacity: 0;
    animation: menuIn .3s ease;
    animation-fill-mode: forwards;
    position: relative;
    line-height: 1.1;
    text-transform: uppercase;
}

.nav-li+.nav-li {
    margin-top: 1rem;
}


.nav-a {
    display: inline-block;
    position: relative;
    transition: 0.4s linear color;

    &:hover {
        cursor: pointer;
    }
}

.nav-a-letters {

    display: flex;
}

@media (hover: hover) and (pointer: fine) {
    .nav-a>* {
        pointer-events: none;
    }
}

.nav-a-stripe {
    position: absolute;
    background-color: map-get($map: $colors, $key: 'deta');
    width: calc(100% + 0.075em);
    left: 0;
    top: 0.5em;
    height: 0.115em;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.8s cubic-bezier(0.4, 0, 0, 1);
}

.nav-a-stripe--yellow {
    background-color: map-get($map: $colors, $key: 'deta3');
    transition-delay: 0.1s;
}

@media (hover: hover) and (pointer: fine) {
    .nav-a:hover .nav-a-stripe {
        transform-origin: left;
        transform: scaleX(1);
    }

    .nav-a:hover .nav-a-stripe--yellow {
        transition-delay: 0s;
    }
}

.nav-a-stripe--turquoise {
    background-color: map-get($map: $colors, $key: 'deta2');
    transition-delay: 50ms;
}

@media (hover: hover) and (pointer: fine) {
    .nav-a:hover .nav-a-stripe--turquoise {
        transition-delay: 50ms;
    }

    .nav-a:hover .nav-a-stripe--purple {
        transition-delay: 0.1s;
    }
}

.nav-a-letters-top {

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
}

.nav-a-letter {
    display: block;
    will-change: transform, opacity;
}

.nav-a-letters .nav-a-letter:nth-child(2n) {
    opacity: 0;
}

.nav-a-letters-top .nav-a-letter:nth-child(2n + 1) {
    opacity: 0 !important;
}

.middleDelay {
    animation-delay: 0.1s;
}

.middleDelay2 {
    animation-delay: 0.15s;
}

.lastDelay {
    animation-delay: 0.2s;
}



.activelink {
    color: map-get($map: $colors, $key: 'deta');
}



//===================Menu button
.buttonMain {
    backdrop-filter: blur(10px);
    text-transform: uppercase;
    font-family: 'Alpino', sans-serif;
    font-size: 1.5rem;
    padding: 10px 15px;
    border: 3px solid map-get($map: $colors, $key: 'deta');
    border-radius: 100px;
    background: none;
    color: map-get($map: $colors, $key: 'deta');
    background-image: url("../img/mountainsButton.png");
    background-position: 0% -20%;
    background-size: 200px;
    overflow: hidden;
    background-repeat: no-repeat;
    font-weight: 800;
    transition: ease 0.3s;
    margin-left: 20px;

    &:hover {
        border-color: map-get($map: $colors, $key: 'main');
        color: map-get($map: $colors, $key: 'secu');
        background-position: 0% 50%;
    }
}

.buttonForest {
    @extend .buttonMain;

    text-decoration: none;
    margin-left: 0px;
    background-position: 0% -60%;
    background-size: 150px;
    background-image: url("../img/ForestButton.png");
    align-items: center;

    &:hover {
        & img {
            filter: brightness(0) invert(1);
        }
    }

    & img {
        // transition: 1s ease;
        // position: absolute;
        transition-delay: .05s;
        align-self: center;
        margin: 0px 10px 5px 0px;
        top: 0px;
        bottom: 0px;
        // margin: auto;
        width: 16px;
    }


}


//====================LinkButton

.linkButton {
    border: 3px solid map-get($map: $colors, $key: 'deta');
    padding: 2px 20px;
    border-radius: 10px;
    text-decoration: none;
    font-size: 1.5rem;
    color: map-get($map: $colors, $key: 'deta');
    font-weight: 700;
    position: relative;
    // padding: 0px;
    transition: ease .2s;
    display: flex;
    align-items: center;
    background-color: map-get($map: $colors, $key: "main");
    width: fit-content;

    &:after {
        position: absolute;
        left: -5px;
        top: 0px;
        scale: 0;
        content: '';
        scale: 0;
        width: 10px;
        height: 10px;
        background-size: cover;
        background-image: url('../img/star.svg');
        transition: .3s ease;
    }

    &:before {
        position: absolute;
        right: 0px;
        scale: 0;
        bottom: 0;
        content: '';
        scale: 0;
        width: 7px;
        height: 7px;
        background-size: cover;
        rotate: 180deg;
        background-image: url('../img/star.svg');
        transition: .3s ease;
        transition-delay: .1s;
    }

    &:hover {
        border: 3px solid map-get($map: $colors, $key: 'main');
        color: map-get($map: $colors, $key: 'deta3');

        &:after,
        &:before {
            rotate: 90deg;
            scale: 3;
        }
    }

    &:disabled {
        opacity: 0.7;

        &:hover {
            border: 3px solid map-get($map: $colors, $key: 'deta');
            color: map-get($map: $colors, $key: 'deta');

            &:after,
            &:before {
                rotate: 90deg;
                scale: 0;
            }

            cursor: auto;
        }
    }
}

.SecundaryButton {
    background-color: map-get($map: $colors, $key: "main");
    text-decoration: none;
    width: fit-content;
    color: map-get($map: $colors, $key: "secuBlur2");
    font-size: 1.5rem;
    border: 3px map-get($map: $colors, $key: "secuBlur2") dashed;
    padding: 2px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: 0.3s ease;
    opacity: 1;
    border-radius: 8px;

    font-weight: 700;


    & img {
        opacity: .5;
        transition: 0.3s ease;
        transform: translate(-1px);
        height: 20px;
        width: 20px;
    }

    &:hover {
        border: 3px map-get($map: $colors, $key: "deta") dashed;
        border-left: 3px map-get($map: $colors, $key: "deta") dashed;
        opacity: 1;
        color: map-get($map: $colors, $key: "deta");
        font-weight: 600;

        & img {
            opacity: 1;
            transform: translate(1px);
        }
    }

    &:disabled{
        &:hover {
            border: 3px map-get($map: $colors, $key: "secuBlur2") dashed;
            color: map-get($map: $colors, $key: "secuBlur2");    
            cursor: auto;
            & img {
                opacity: .5;
                transform: translate(-1px);
            }
        }
    }
}
.IconButtonDelete {
    padding: 7px;
    background-color: map-get($map: $colors, $key: 'deta2Blur');
    border-radius: 4px;
    transition: .3s ease;

    & img {
        transition: .3s cubic-bezier(0.46, -1.39, 0, 2.14);
        height: 20px;
        width: 20px;
    }

    &:hover {

        background-color: map-get($map: $colors, $key: 'deta3');

        & img {
            transform: rotate(20deg);
        }
    }

    &:disabled {
        &:hover {
            background-color: map-get($map: $colors, $key: 'deta2Blur');
            cursor: auto;

            & img {
                transform: rotate(0deg);
            }

            // cursor: grab;
        }
    }
}
.IconButtonDeleteSmall{
    @extend .IconButtonDelete;
    & img{
        width: 16px;
        height: 16px;
    }
}
.IconButton {
    padding: 7px;
    background-color: map-get($map: $colors, $key: 'deta2Blur');
    border-radius: 4px;
    transition: .3s ease;

    & img {
        height: 18px;
        width: 18px;
    }

    &:hover {
        background-color: map-get($map: $colors, $key: 'deta2');
        // cursor: grab;
    }

    display: flex;
    flex-direction: row;
    gap: 10px;
    font-weight:500;
    align-items: center;
    font-size: 1.08rem;

    &:disabled {
        &:hover {
            background-color: map-get($map: $colors, $key: 'deta2Blur');
            cursor: auto; // cursor: grab;
        }
    }
}


.AddAnswer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 500;


    & img {
        background-color: map-get($map: $colors, $key: 'deta2');
        padding: 2px;
        border-radius: 100%;
        width: 20px;
        // height: 10px;
        // width: 15px;

    }
    &:hover{
        color: map-get($map: $colors, $key: 'deta2');
    }
}

input[type='radio'] {
    width: 20px;
    accent-color: map-get($map: $colors, $key: 'deta');
}


.closeButton{
    filter: brightness(0) invert(1);
    &:hover{
        filter: brightness(1) invert(0);
    }
}