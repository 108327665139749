footer{
    background-image: url('../img/footerBg.png');
    background-repeat: repeat-x;
    background-size: 800px 100%;
    background-position: 0% 100%;
    padding: 80px 30px 30px;
    display: flex;
    justify-content: space-between;
    bottom: 0px;
}
.socialLogo{
    opacity: 0.7;
    transition: 0.3s;
    &:hover{
        opacity: 1;
    }
}

.copy{
    font-size: 1.5rem;
    color: map-get($map:$colors, $key: 'secu');
    opacity: 0.7;
}
.footerInner{
    gap: 3px;
    display: flex;
    flex-direction: row;
}
@media (max-width: 310px) {
    footer{
        justify-content: center;
    }
    .footerInner{
        margin-top: 10px;
        justify-content: center;
    }

}