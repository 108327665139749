.formForSearch {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    & input{
        width: auto;
        flex-grow: 1;
    }
    & div{
        // flex-grow: 1;
        & select{
            width: 100%;
        }
    }
}

.QuizzesContainer {
    margin-top: 20px;
}

.card {
    overflow: hidden;
    border: 0px;
    border: 3px solid map-get($map: $colors, $key: 'main');
    border-radius: 12px;
    background-color: map-get($map: $colors, $key: 'mainBlur');
    display: flex;
    transition: .1s ease;

    & .card-body {
        padding: 10px;
        width: 100%;
        position: relative;
        
    }

    & h2 {
        color: map-get($map: $colors, $key: 'secuBlur');
        text-align: left;
        font-size: 1.6rem;
        font-weight: 500;
        margin: 10px 0px 0px;
    }

    &:hover {
        border: 3px dashed map-get($map: $colors, $key: 'secuBlur2');

    }
}

.QuestionsContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ResolveBanner{
    width: 100%;
    border-radius: 12px;
    max-height: 200px;
    object-fit: cover;

}

.quiestionAndAsideCont{
    display: flex;
    flex-direction: column;
    gap: 20px;
    & h1{
        padding: 0px;
    }
}
.SpeColor{
    color: map-get($map: $colors, $key: 'deta');
}

.quizDescription{
    margin: 0px;
    font-weight: 500;
    padding: 10px;
    border-radius: 8px;
    color:  map-get($map: $colors, $key: 'main');
    background-color: map-get($map: $colors, $key: 'secuBlur');
    font-size: 1.5rem;

    & span{
        font-weight: 600;
        color: map-get($map: $colors, $key: 'main');
    }
}
.MoreInfo {
    position: absolute;
    display: flex;
    width: calc(100% - 20px);
    justify-content: space-between;
    top: -15px;
    opacity: .9;
    & span{
        color: map-get($map: $colors, $key: 'main');
        padding: 2px 10px;
        background-color: map-get($map: $colors, $key: 'deta');
        border-radius: 100px;
        font-size: 1.1rem;
        font-weight: 600;

    }
}

.BoxResult{
    // display: flex;
    
    width: fit-content !important;
    & h3{
        text-align: center;
        font-size: 3rem;
        font-weight: 600;
    }
}