.banner {
    margin-top: -115px;
    height: 1000px;
    overflow: hidden;
}


.clouds {
    height: 100%
}

.bannerText {
    @include flex(row-reverse, flex-end, center, center, wrap);
    height: 70%;
}

.homeTitle {
    font-size: 50px;
    text-transform: uppercase;
    opacity: 0.5;
}

.leftDown {
    width: 100%;
    height: 95%;
    @include flex(row-reverse, flex-end, flex-end, center, wrap);

    @media screen and (max-width: 1270px) {
        justify-content: center
    }
}

.nameOfSite {
    height: 50%;
    opacity: 0.8;
    flex-direction: column;

    & h1 {
        font-family: 'Alpino', sans-serif;
        font-size: 6.5rem;
        font-weight: 900;
    }

    & p {
        font-size: 1.8rem;
    }
}

@keyframes TapeAnimation {
    from {
        opacity: 0;
        transform: scale(130%);
    }

    to {
        opacity: 1;
        transform: scale(100%);
    }
}

@keyframes TapeAnimationOut {
    from {
        opacity: 1;
        // transform: scale(130%);
        transform: translate(0%, 0%);
    }

    to {
        opacity: 0;
        transform: translate(-20%, 0%);
    }
}

.borderImgs {
    padding: 15px;
    width: fit-content;
    background-color: map-get($map: $colors, $key: 'secuBlur');
    position: relative;

    & .tapeTop {
        z-index: 1;
        position: absolute;
        rotate: -30deg;
        width: 300px;
        height: 100px;
        top: -10px;
        left: -100px;
        transform: scale(130%);
        opacity: 0;
    }

    & .tapeBellow {
        z-index: 1;
        position: absolute;
        rotate: -50deg;
        width: 300px;
        height: 100px;
        bottom: -10px;
        right: -100px;
        transform: scale(130%);
        opacity: 0;
    }

    & p {
        color: map-get($map: $colors, $key: 'main');
        margin-top: 10px;
        margin-bottom: 0px;
        font-size: 2rem;
        font-weight: 600;
    }

    & span {
        color: map-get($map: $colors, $key: 'main');
        font-size: 2.5em;
        font-weight: 800;
    }

    @media screen and (max-width: 1200px) {


        width: 100%;

        & img {
            object-fit: cover;
            width: 100%;
            height: 40px;
        }
    }
}

.SectionSidebySide {
    overflow-x: clip;
    padding: 30px 30px 90px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    justify-items: center;
    align-items: center;

    .TextContainerLanding {
        max-width: 550px;

        & h2 {
            font-weight: 800;
            font-size: 6em;
        }

        & p {
            margin: 40px 0px 20px;
            font-size: 1.7rem;
        }
    }

    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
        gap: 6rem;

        .TextContainerLanding {
            max-width: 100%;

        }
    }
}

.SectionSidebySideSpe {
    @extend .SectionSidebySide;
    // margin-bottom: 30px;
    align-items: flex-start;

}

.figoreToMoveImgEffect {
    overflow: hidden;
    height: 700px;

    & img {
        height: 140%;
        max-width: 660px;
        object-fit: cover;
        filter: contrast(1.15);
    }

    @media screen and (max-width: 1200px) {
        height: fit-content;

        & img {
            height: 450px;
            object-fit: cover;
        }
    }
}

.toSmall {
    @media screen and (max-width: 1200px) {
        // bo
        width: 100%;
    }
}

.tapeFull {
    animation: TapeAnimation .2s ease-in;
    animation-fill-mode: both
}

.tapeOut {
    animation: TapeAnimationOut .2s ease-in;
}

.parallaxText {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;

    & .scroller {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 64px;
        display: flex;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;

    }

    & span {
        color: map-get($map: $colors, $key: 'deta2');
        opacity: .8;
        font-size: 9rem;
        font-weight: 900;
        display: block;
        margin-right: 30px;
        margin: -40px 30px;

    }
}

.TextContainerSection {
    margin: 60px 0px 100px;

}

.imageOfcolage {
    padding: 10px;

    &:first-child {
        & figure {
            overflow: hidden;
            height: 450px;

            & img {
                height: 120%;
                width: 100%;
            }
        }
    }

    & img {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }

    & p {
        font-size: 1.5rem;
    }

    & span {
        font-size: 2rem;
    }

    @media screen and (max-width: 1200px) {
        & img {
            width: 400px;
            // object-fit: cover;
        }
    }

}

.collage {
    display: flex;
    align-items: center;
    gap: 40px;

    & .part2 {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @media screen and (max-width: 950px) {
            display: none;
        }
    }

}