// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:wght@400;500;700&display=swap');
@import url("https://api.fontshare.com/v2/css?f[]=alpino@400,500,700,900&display=swap");
$colors: (
    'main':#2F0C29,
    'secu': #ffff,
    'secuBlur':rgb(255, 255, 255, .9),
    'secuBlur2':rgb(255, 255, 255, .5),
    'deta': #ff755d,
    'deta2': #B95CA7,
    'deta2Blur': #b95ca638,
    'deta3': #DB2E3C,
    'mainBlur': #13051091,
);

@mixin flex($flex_d, $al_c, $ju_c, $al_i, $wr) {
    display: flex;
    flex-direction: $flex_d;
    align-content: $al_c;
    align-items: $al_i;
    justify-content: $ju_c;
    flex-wrap: $wr;
}

* {
    margin: 0;
    padding: 0;
    color: map-get($map: $colors, $key: 'secu');
    font-family: "Alpino", sans-serif;
    &:focus{
        outline: none;
    }
}
figure{
    margin: 0px;
}

button{
    background:none;
    border: none;
    &:hover{
        cursor: pointer;
    }
}



html {
    background-size: 25px 25px;
    font-size: 80%;
    background-attachment: fixed
}

body {
    scroll-behavior: smooth;
    background-color: map-get($map: $colors, $key: 'main');
}

.center {
    width: 100%;
    height: 100%;
    @include flex(row-reverse, center, center, center, wrap);
}
.content {
    overflow-x:clip;
    overflow-y: visible;
    display: flex;
    // align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 248px);
}
.Page404{
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 50px;
    & img{
        width: 600px;
    }
    & h1{
        font-weight: 700;
        max-width: 350px;
    }
}

label {
    font-weight: 600;
    font-size: 1.5rem;
    & span{
        color: map-get($map: $colors, $key: 'deta');
    }
}

h1{
    z-index: 1;
}
.spaceBetween{
    display: flex;
    align-content: space-between;
    justify-content: space-between


}
.AddGap{
    display: flex;
    gap: 10px;
}