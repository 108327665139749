@keyframes colorChange {
    0% {
        color: map-get($map: $colors, $key: 'deta');
    }

    50% {
        color: map-get($map: $colors, $key: 'deta3');
        opacity: 1;
    }

    100% {
        color: map-get($map: $colors, $key: 'deta');
    }
}

.formContFullInfo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;

    & div {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .dificultLable {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & span {
            font-size: 1.2rem;
            font-weight: 600;
            margin: 0px;
            color: #4FFFB0;

            & img {
                width: 15px;
                margin-bottom: 2px;
            }

        }

        & .angry {
            color: #ff6572;
        }
    }

    & .InputNumberAndRange {
        display: grid;
        grid-template-columns: 50px 1fr;
        gap: 20px;
        align-items: center;

        &:first-child {
            border-radius: 8px;
        }

        & input[type=number] {
            border-radius: 8px;
            border: none;
            font-size: 1.5rem;
            padding: 0px 0px 0px 10px;
            font-weight: 900;
            color: map-get($map: $colors, $key: 'main');
            background-color: map-get($map: $colors, $key: 'secuBlur');
            width: 100%;
        }
    }

    & .twoColumnsFormSection {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: space-between;
        flex-wrap: wrap;

        & div {
            width: auto;
        }
    }

    & .buttonsCont {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
    }

}

.PhotoToCreate {
    object-position: right 0 bottom -50px;
    width: 450px !important;
    height: 400px !important;
    filter: invert(5%) grayscale(100%);

    @media screen and (max-width: 1200px) {
        width: 100% !important;
        object-position: right 0 bottom 0px;

    }
}

.CreateImageCont {
    display: flex;
    justify-content: center;
    // height: 100% o !important;
    width: 100%;
    // overflow: hidden;
}

.PhotoToCreateWorking {
    filter: invert(5%) grayscale(10%);
}

.AsideForm {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & h1 {
        opacity: 1;
        font-size: 4rem;
        font-weight: 800;

        & span {
            opacity: 0.9;
            animation: colorChange 6s cubic-bezier(0.03, 0.41, 1, 0.06) infinite;
        }
    }

    & div {
        width: 100%;
    }

    & textarea {
        font-weight: 500;
        font-size: 1.4rem;
        background-color: map-get($map: $colors, $key: 'secuBlur');
        width: 100%;
        height: 160px;
        border-radius: 8px;
        resize: none;
        color: map-get($map: $colors, $key: 'main');
        padding: 10px;
        border: 4px solid map-get($map: $colors, $key: 'secuBlur');

        &:focus {
            border: 4px solid map-get($map: $colors, $key: 'deta');

        }
    }
}


.slider {
    appearance: none;
    // width: 100%;
    height: 10px;
    border-radius: 20px;
    background: map-get($map: $colors, $key: 'secuBlur');
    // outline: none;
    opacity: 0.8;
    // -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    // -webkit-appearance: none;
    appearance: none;
    border-radius: 20px;
    width: 28px;
    height: 28px;
    border: 5px solid map-get($map: $colors, $key: 'main');
    background: map-get($map: $colors, $key: 'deta');
    cursor: pointer;
}

.ApiKeyNeeded {
    text-decoration: none;
    color: map-get($map: $colors, $key: 'main');
    font-size: 1.3rem;
    font-weight: 800;

    &:hover {
        color: map-get($map: $colors, $key: 'mainBlur');

        & img {
            opacity: .5;

        }

    }

    & img {
        filter: invert(100%);

    }
}

.fileCont {
    display: flex;
    flex-direction: row !important;
    align-items: center;

    & img {
        margin: 10px;
        width: 20px;
        height: 20px;
    }

}

.FileInput {
    display: flex;
    align-content: center;
    gap: 10px;
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    padding: 8px 13px 8px 8px;
    border-radius: 8px;
    background-color: map-get($map: $colors, $key: 'deta2Blur');

    // text-overflow: ellipsis; 
    & span {
        color: map-get($map: $colors, $key: 'secu');
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    max-width: 240px;
    // overflow: hidden;
    white-space: nowrap;
    position: relative;

    & img {
        margin: 0px;
        height: 30px;
        width: 30px;
        border-radius: 5px;
        padding: 5px;
        background-color: map-get($map: $colors, $key: 'deta');
    }

    &:hover {
        cursor: pointer;
    }
}

textarea::-webkit-scrollbar {
    width: 5px;
}

textarea::-webkit-scrollbar-track {
    background-color: map-get($map: $colors, $key: 'deta');
}

textarea::-webkit-scrollbar-thumb {
    background-color: map-get($map: $colors, $key: 'main');
}

.divInFileCont {
    position: relative;

    .deleteButton {
        z-index: 100;
        position: absolute;
        background-color: map-get($map: $colors, $key: 'deta');
        top: -10px;
        right: -10px;
        width: 25px;
        height: 25px;
        font-size: 1.2rem;
        font-weight: 800;
        // text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        display: none;
    }

    &:hover {
        .deleteButton {
            display: block;
        }
    }
}

//==================================================== Questions design =============================

.quiestionAndAsideCont {
    align-self: center;

    width: 100%;
    max-width: 900px;

    margin: 0px 30px 40px;

    & .quizesCont {
        display: flex;
        flex-direction: column;
        // gap: 20px;

        & .FunctionsQuiestions {
            gap: 20px;
            align-items: center;
            flex-wrap: wrap;
            top: 0;
            background-color: map-get($map: $colors, $key: 'main');
            margin-top: -115px;
            padding: 115px 0px 20px;
            // position: sticky;
            display: flex;
            justify-content: space-between;

            & div {
                flex-wrap: wrap;

                display: flex;
                gap: 20px;
            }
        }

        & .QuestionsContainer {
            display: flex;
            flex-direction: column;
            gap: 20px;

            & .buttonEnd {
                flex-wrap: wrap;

                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        width: calc(100% - 60px);
    }
}

.Question {
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: map-get($map: $colors, $key: 'mainBlur');

    & h3 {
        margin: 0px;
        font-weight: 500;
        font-size: 2rem;
    }

    & h4 {
        margin: 0px;
        font-weight: 400;
        font-size: 1.5rem;
    }

    & .listAnswers {
        margin: 0px;
        display: grid;
        column-gap: 20px;
        row-gap: 5px;
        grid-template-columns: 1fr 1fr;


        & li {
            font-weight: 500;

            list-style: none;
            font-size: 1.5em;
        }
    }
}

.FunctionsQuestionCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & div {
        display: flex;
        flex-direction: row;
        gap: 10px;

        & h4 {
            align-self: center;
            margin: 0px;
        }
    }
}

.HorizontalSection {
    display: flex;
    flex-direction: row;
    gap: 10px;

    & div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-grow: 1;
    }

    & .ImageInputCont {
        & input {
            display: none;
        }

        width: 0px;
        min-width: 200px;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
        }
    }


}

.inputAnswer{
    background: none;
    border: none;
    // width: 100%;
    flex-grow: 1;
    // flex-shrink: 1;
    // width: 100%;
    font-size: 1.4rem;
    color: map-get($map: $colors, $key: 'secuBlur2');

    border-bottom: 2px solid map-get($map: $colors, $key: 'secuBlur2');
    &:focus{
        color: map-get($map: $colors, $key: 'secu');
        border-bottom: 2px solid map-get($map: $colors, $key: 'deta');

    }
}

.AnswerCont{
    // width: 100%;
    display: flex;
    gap: 10px;
}

.AnswersConts{
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    // flex-grow: 1;
    overflow: auto;
    // height: 100%;
    max-height: 245px;
}


.AnswersConts::-webkit-scrollbar {
    width: 5px;
}

.AnswersConts::-webkit-scrollbar-track {
    background-color: map-get($map: $colors, $key: 'main');
}

.AnswersConts::-webkit-scrollbar-thumb {
    background-color: map-get($map: $colors, $key: 'deta');
}



.NoQuizzesSection{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    & .ImageAsBanner{
        height: 430px;
        @media screen and (max-width: 800px) {
            width: 100%;
            height: auto;
        }
    }
    & div{
        & p{
            font-size: 2.4rem;
        }
    }
}


.inputAndLabeGap{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

select {
    flex-grow: 1;
    background-color: map-get($map: $colors, $key: 'secuBlur');
    border: 3px solid map-get($map: $colors, $key: 'secu');
    color: map-get($map: $colors, $key: 'main');
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 8px;
    padding: 6px 10px;

    &:focus {
        border: 3px solid map-get($map: $colors, $key: 'deta');

    }

    & option {
        color: map-get($map: $colors, $key: 'main');
        font-weight: 500;
    }

    & optgroup{
        color: map-get($map: $colors, $key: 'main');

    }
}
.specialColor{ 
    opacity: 0.9;
    // font-weight: 800;
    animation: colorChange 5s ease infinite;
}

.uploadingBanner{
    & img{
        width: 400px;
    }
    width: fit-content;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    & h2{
        text-align: center;
        margin: 0px;
    }
}

.loader2 {
    height: 4px;
    width: 100%;
    --c:no-repeat linear-gradient(#ff755d 0 0);
    background: var(--c),var(--c),#13051091;
    background-size: 60% 100%;
    animation: l16 5s infinite;
  }
  @keyframes l16 {
    0%   {background-position:-150% 0,-150% 0}
    66%  {background-position: 250% 0,-150% 0}
    100% {background-position: 250% 0, 250% 0}
  }