nav {
    right: 0;
    left: 0;
    top: 0;
    z-index: 10;
    position: sticky;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    margin: auto;

    & div {
        display: flex;
        flex-direction: row;
        gap: 20px;
        padding: 10px;
    }
}

.logoNav {
    opacity: 0.7;
    width: 40px;
    height: 40px;
    transition: ease 0.3s;
    &:hover{
        opacity: 1;
    }
}

@keyframes menuIn {
    from {
        opacity: 0;
        transform: translateX(-10%);
    }

    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

.fullnav {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    top: 0px;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: map-get($map: $colors, $key: 'mainBlur');
    backdrop-filter: blur(3px);

}
