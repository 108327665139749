.dashboarSecction {
    & .aside {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    grid-template-columns: 1fr 2px 1fr;
    align-items: normal;

    & label {
        & img{
            margin-left: 0px;
        }
    }

    // & div {
    //     // margin-left: 50px;
    // }

    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
        gap: 10px;

    }

    & .separation {
        background-color: map-get($map: $colors, $key: 'deta2');
        opacity: 0.5;
        width: 100%;
    }

    & .Empty {

        & p {
            max-width: 350px;
            font-size: 1.7rem;
            font-family: 500;
        }
    }
}

.pageInto {
    max-width: 950px;
    padding: 0px 30px;
    left: 50%;
    width: fit-content;
    position: relative;
    margin-bottom: 20px;
    // right: 0px;
    // margin: auto;
    transform: translate(-50%, 0%);
    // justify-self: center;
    font-weight: 800;
    font-size: 4rem;

    & span {
        opacity: 0.9;
        font-weight: 800;
        animation: colorChange 5s ease infinite;

    }
}

.inputText {
    font-weight: 500;
    font-size: 1.4rem;
    background-color: map-get($map: $colors, $key: 'secuBlur');
    width: 100%;
    // height: 160px;
    border-radius: 8px;
    resize: none;
    color: map-get($map: $colors, $key: 'main');
    padding: 4px 10px;
    border: 4px solid map-get($map: $colors, $key: 'secuBlur');

    &:focus {
        border: 4px solid map-get($map: $colors, $key: 'deta');

    }

    &:disabled {
        opacity: .76;
    }

    // opacity: 0.6;
}

.info {
    margin: 10px;
    width: 20px;
    height: 20px;
}

.ApiKeyEdit {
    & div {
        flex-direction: row-reverse;
        display: flex;
        gap: 10px;
    }

    display: flex;
    gap: 10px;

    & .eye {
        padding: 10px 12.5px;
        border-radius: 8px;
        background-color: map-get($map: $colors, $key: 'deta');

        & img {
            height: 1.4rem;
            width: 1.4rem;
        }

        position: relative;
        // overflow: hidden;

    }

    & .SaveButton {
        text-wrap: nowrap;
        // width: 10px;
        padding: 2px 20px;
        font-size: 1.4rem;
        font-weight: 600;
        background: none;
        border: solid 4px map-get($map: $colors, $key: 'deta');
        color: map-get($map: $colors, $key: 'deta');

    }

    & input[type='text']~button {
        &::after {
            content: '';
            position: absolute;
            width: 30px;
            height: 3px;
            top: 73%;
            left: 40%;
            border-radius: 100px;
            // top: 50%;
            // left: 50%;
            transform: translate(-50%, 0%);
            rotate: 45deg;
            background-color: map-get($map: $colors, $key: 'secu');
        }
    }
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
    width: 60px;
    height: 10px;
    background: radial-gradient(circle closest-side, map-get($map: $colors, $key: 'deta') 90%, #0000) calc(100%/3) 0/calc(100%/4) 100%;
    animation: l2 0.5s infinite linear;
    margin: 0px;
}

@keyframes l2 {
    100% {
        background-position: 0 0
    }
}

.user {
    flex-grow: 1;
    max-width: 100%;

    & img {
        padding: 0px;
    }

    &:hover {
        cursor: auto;
    }
}

.userSettingCont {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &>button {
        margin-top: 10px;
    }

    & figure {
        margin: 0px;
    }
}

.chartCont {
    display: flex;
    height: 190px;

    // flex-grow: 1;
    & tspan {
        font-size: 1.4rem;
        font-weight: 600;
    }
}

.ChartFullCont {
    flex-grow: 1;
}

.YourQuizzesCont{
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    & button{
        flex-grow: 1;
    }
    & img{
        height: 100px;
        object-fit: cover;

    }
}

.position-sticky{
    position: sticky;
    height: fit-content;
    top: 130px;
    @media screen and (max-width: 1200px) {
        position: relative !important;
        height: auto;
        top: 0px;

    }
}
